import { useEffect, useState } from "react";
const isBrowser = typeof window !== "undefined";

export const useMediaQuery = (query) => {
  const mediaMatch = isBrowser ? window.matchMedia(query) : null;

  const [matches, setMatches] = useState(mediaMatch && mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e?.matches);
    if (mediaMatch) {
      mediaMatch?.addEventListener("change", handler);
    }
    return () => mediaMatch?.removeEventListener("change", handler);
  }, [matches, query]);
  return matches;
};
