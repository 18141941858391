import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';
import { Link } from 'gatsby';
import { useMediaQuery } from '../../utilities/useMediaQuery';
import SendMessageModal from '../send-message-modal';

// This file is used at : [ pricing.js ]

const PricingComponent = props => {
  const {
    pricingTitle,
    pricingCardTitle1,
    pricingCardDescription1,
    pricingCardTitle2,
    pricingCardDescription2,
    pricingCardTitle3,
    pricingCardDescription3,
    pricingCardTitle4,
    pricingCardDescription4,
  } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  const windowWidth = useMediaQuery('(max-width: 768px)');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleModalOpen = () => {
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const handlePaginationClick = index => {
    setActiveIndex(index);
    swiperRef.current?.swiper.slideTo(index);
  };

  const onSwiperMount = swiper => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <div className='pricing-section'>
      <div className='full-container'>
        <div className='pricing-section-wrapper'>
          <p
            className='pricing-section-wrapper-title'
            dangerouslySetInnerHTML={{ __html: pricingTitle }}
          />
          <div className='pricing-section-wrapper-buttons'>
            <div
              className={`pricing-section-wrapper-buttons-text ${
                activeIndex === 0 && 'active-button'
              }`}
              style={{
                borderBottomLeftRadius: activeIndex === 0 ? '30px' : '0px',
                borderTopLeftRadius: activeIndex === 0 ? '30px' : '0px',
              }}
              onClick={() => handlePaginationClick(0)}
            >
              <p
                className={`pricing-section-wrapper-buttons-text-title ${
                  activeIndex === 0 && 'active-title'
                }`}
                dangerouslySetInnerHTML={{ __html: pricingCardTitle1 }}
              />
            </div>
            <div
              className={`pricing-section-wrapper-buttons-text ${
                activeIndex === 1 && 'active-button'
              }`}
              onClick={() => handlePaginationClick(1)}
            >
              <p
                className={`pricing-section-wrapper-buttons-text-title ${
                  activeIndex === 1 && 'active-title'
                }`}
                dangerouslySetInnerHTML={{ __html: pricingCardTitle2 }}
              />
            </div>
            <div
              className={`pricing-section-wrapper-buttons-text ${
                activeIndex === 2 && 'active-button'
              }`}
              onClick={() => handlePaginationClick(2)}
            >
              <p
                className={`pricing-section-wrapper-buttons-text-title ${
                  activeIndex === 2 && 'active-title'
                }`}
                dangerouslySetInnerHTML={{ __html: pricingCardTitle3 }}
              />
            </div>
            <div
              className={`pricing-section-wrapper-buttons-text ${
                activeIndex === 3 && 'active-button'
              }`}
              onClick={() => handlePaginationClick(3)}
              style={{
                borderBottomRightRadius: activeIndex === 3 ? '30px' : '0px',
                borderTopRightRadius: activeIndex === 3 ? '30px' : '0px',
              }}
            >
              <p
                className={`pricing-section-wrapper-buttons-text-title ${
                  activeIndex === 3 && 'active-title'
                }`}
                dangerouslySetInnerHTML={{ __html: pricingCardTitle4 }}
              />
            </div>
          </div>
          <div className='pricing-section-wrapper-swiper'>
            <Swiper
              onSlideChange={onSwiperMount}
              ref={swiperRef}
              modules={[EffectCoverflow]}
              effect='coverflow'
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={windowWidth ? 1 : 'auto'}
              coverflowEffect={{
                rotate: 0,
                stretch: windowWidth ? 0 : 150,
                depth: 220,
                modifier: windowWidth ? 0 : 1,
                slideShadows: false,
              }}
            >
              <SwiperSlide key={1}>
                <div
                  className={
                    activeIndex === 0
                      ? 'pricing-section-wrapper-swiper-card'
                      : 'not-active-card'
                  }
                >
                  <p
                    className='pricing-section-wrapper-swiper-card-title'
                    dangerouslySetInnerHTML={{ __html: pricingCardTitle1 }}
                  />
                  <p
                    className='pricing-section-wrapper-swiper-card-description'
                    dangerouslySetInnerHTML={{
                      __html: pricingCardDescription1,
                    }}
                  />
                  <div className='pricing-section-wrapper-swiper-card-team'>
                    <p className='pricing-section-wrapper-swiper-card-team-title'>
                      This team includes:
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      ¼ Time UX/UI Designer
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      ¾ Fullstack Engineers
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide key={2}>
                <div
                  className={
                    activeIndex === 1
                      ? 'pricing-section-wrapper-swiper-card'
                      : 'not-active-card'
                  }
                >
                  <p
                    className='pricing-section-wrapper-swiper-card-title'
                    dangerouslySetInnerHTML={{ __html: pricingCardTitle2 }}
                  />
                  <p
                    className='pricing-section-wrapper-swiper-card-description'
                    dangerouslySetInnerHTML={{
                      __html: pricingCardDescription2,
                    }}
                  />
                  <div className='pricing-section-wrapper-swiper-card-team'>
                    <p className='pricing-section-wrapper-swiper-card-team-title'>
                      This team includes:
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      ¼ Time UX/UI Designer
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      ¼ Time Product Manager
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      ¼ Time DevOps Engineer
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      ¼ Time QA Engineer
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      2 Fullstack Engineers
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide key={3}>
                <div
                  className={
                    activeIndex === 2
                      ? 'pricing-section-wrapper-swiper-card'
                      : 'not-active-card'
                  }
                >
                  <p
                    className='pricing-section-wrapper-swiper-card-title'
                    dangerouslySetInnerHTML={{ __html: pricingCardTitle3 }}
                  />
                  <p
                    className='pricing-section-wrapper-swiper-card-description'
                    dangerouslySetInnerHTML={{
                      __html: pricingCardDescription3,
                    }}
                  />
                  <div className='pricing-section-wrapper-swiper-card-team'>
                    <p className='pricing-section-wrapper-swiper-card-team-title'>
                      This team includes:
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      ½ UX/UI Designer
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      ½ Product Manager
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      ½ DevOps Engineer
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      ½ QA Engineer
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      4 Fullstack Engineers
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      Bonus: ½ Project Manager
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide key={4}>
                <div
                  className={
                    activeIndex === 3
                      ? 'pricing-section-wrapper-swiper-card'
                      : 'not-active-card'
                  }
                >
                  <p
                    className='pricing-section-wrapper-swiper-card-title'
                    dangerouslySetInnerHTML={{ __html: pricingCardTitle4 }}
                  />
                  <p
                    className='pricing-section-wrapper-swiper-card-description'
                    dangerouslySetInnerHTML={{
                      __html: pricingCardDescription4,
                    }}
                  />
                  <div className='pricing-section-wrapper-swiper-card-team'>
                    <p className='pricing-section-wrapper-swiper-card-team-title'>
                      This team includes:
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      1 UX/UI Designer
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      1 Engineering Manager
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      5 Fullstack Engineers
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      1 DevOps Engineer
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      1 QA Engineer
                    </p>
                    <p className='pricing-section-wrapper-swiper-card-team-description'>
                      Bonus: Project Manager, Product Manager
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <div className='pricing-section-wrapper-swiper-links'>
              <Link
                to='https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1DvKx-W5bQIQqYRPigG4-BJhmnc3tqIcrtPt8q4Tg_UCGIc7Y65IUVAloWN8BlJkbqrmDwb4r2'
                target='_blank'
                className='pricing-section-wrapper-swiper-links-link'
              >
                Book A Call
              </Link>
              <button
                onClick={handleModalOpen}
                className='pricing-section-wrapper-swiper-links-link pricing-section-wrapper-swiper-links-link--secondary'
              >
                Send A Message
              </button>
            </div>
            <SendMessageModal onClose={handleModalClose} isOpen={modalIsOpen} />
            <div className='pricing-section-wrapper-swiper-pagination'>
              <div
                className={`pricing-section-wrapper-swiper-pagination-bullet ${
                  activeIndex === 0 && 'active-bullet'
                }`}
                onClick={() => handlePaginationClick(0)}
              ></div>
              <div
                className={`pricing-section-wrapper-swiper-pagination-bullet ${
                  activeIndex === 1 && 'active-bullet'
                }`}
                onClick={() => handlePaginationClick(1)}
              ></div>
              <div
                className={`pricing-section-wrapper-swiper-pagination-bullet ${
                  activeIndex === 2 && 'active-bullet'
                }`}
                onClick={() => handlePaginationClick(2)}
              ></div>
              <div
                className={`pricing-section-wrapper-swiper-pagination-bullet ${
                  activeIndex === 3 && 'active-bullet'
                }`}
                onClick={() => handlePaginationClick(3)}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
