import React from 'react';
import Layout from '../component/layout';
import PricingComponent from '../component/Pricing/pricingComponent';
import FooterComponent from '../component/footerComponent';
import { graphql } from 'gatsby';

const Pricing = props => {
  const {
    data: {
      wpPage: {
        seo,
        homePageReviewSection: {
          homePageReviewTitle,
          homePageReviewAgreedDescription,
          homePageReviewAgreedClient,
          homePageReviewAgreedPosition,
          homePageReviewBashDescription,
          homePageReviewBashClient,
          homePageReviewBashPosition,
          homePageReviewHyraDescription,
          homePageReviewHyraClient,
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout title={title} seo={seo}>
      <PricingComponent
        pricingTitle={homePageReviewTitle}
        pricingCardTitle1={homePageReviewAgreedDescription}
        pricingCardDescription1={homePageReviewAgreedClient}
        pricingCardTitle2={homePageReviewAgreedPosition}
        pricingCardDescription2={homePageReviewBashDescription}
        pricingCardTitle3={homePageReviewBashClient}
        pricingCardDescription3={homePageReviewBashPosition}
        pricingCardTitle4={homePageReviewHyraDescription}
        pricingCardDescription4={homePageReviewHyraClient}
      />
      <FooterComponent />
    </Layout>
  );
};

export default Pricing;
export const query = graphql`
  {
    wpPage(slug: { eq: "pricing" }) {
      seo {
        title
        metaDesc
      }
      homePageReviewSection {
        homePageReviewTitle
        homePageReviewAgreedDescription
        homePageReviewAgreedClient
        homePageReviewAgreedPosition
        homePageReviewBashDescription
        homePageReviewBashClient
        homePageReviewBashPosition
        homePageReviewHyraDescription
        homePageReviewHyraClient
      }
    }
  }
`;
